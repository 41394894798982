<template>
  <div id="Aluno_Meus_Talentos" class="pl-1">
    <ModalListaExercicios
      :exercicios="exercicios"
      :label="labelExercicios"
      @atualizaDados="atualizaDados"
    />
    <Loader v-if="loader" />
    <div class="here bg-edf0f5 py-5">
      <div class="container">
        <div class="row bg-fff shadow-sm border-default shadow-sm pb-5">
          <div class="d-flex j-c-center w-100 h-100 mt-5" v-if="noExercicios">
            <span>Infelizmente ainda não existema temas cadastrados...</span>
          </div>
          <div class="col-md-12 text-left pl-0 mb-3 p-3">
          <h2 class="title-dados pb-2">{{$route.params.nameAluno}}</h2>
          <router-link :to="`/administrador/unidades-e-instituicoes/${$route.params.idunidade}/${$route.params.unidade}/${$route.params.idclass}/${$route.params.class}`">
          <button class="mt-3" style="background: #0E5CAF; color: white; padding: 4px 19px; font-size: 16px">Voltar</button>
          </router-link>
          </div>
          <div
            class="col-md-11 mx-auto text-center"
            v-for="(item, index) in aulas"
            :key="index"
            :id="`aula_${item.id}`"
          >
            <h2
              v-if="item.tema.length > 0 && item.tema[0].count != 0"
              class="title-meus-temas text-center pt-4 pb-3 text-capitalize"
            >
              {{ item.Name }}
            </h2>
            <div class="row">
              <!--elemento para apresentar status das aulas-->
                <div
                  v-for="(itemTema, indexTema) in item.tema"
                  :key="`${itemTema.tema}_${indexTema}`"
                  :class="`${itemTema.exercicios.length > 0 ? 'col-md-6 the-border py-3' : ''}`"
                > 
                  <div
                    class="a-concluir row mx-0 py-3 px-3 shadow-sm"
                    v-if="itemTema.count != 0"
                  >
                    <div
                      class="col-md-1 d-flex align-items-center justify-content-center arrow"
                    >
                      <img
                        src="@/assets/aluno/Icon-material-label-outline.png"
                        alt="Icon-material-label-outline.png"
                      />
                    </div>
                    <div
                      @click="
                        showModalExercicios(itemTema.name, itemTema, indexTema)
                      "
                      :class="
                        `col-md-11 row mx-0 bg-dfdfdf shape-show-status-aulas position-relative ${
                          itemTema.concluded > 0 &&
                          itemTema.concluded < itemTema.exercicios.length
                            ? 'bg-0e5caf'
                            : itemTema.concluded == 0
                            ? 'bg-e4e5e8'
                            : 'bg-42cc7e'
                        }`
                      "
                    >
                      <small
                        :class="
                          `position-absolute shape-show-status-aulas-idAula ${
                            itemTema.concluded > 0 &&
                            itemTema.concluded < itemTema.exercicios.length
                              ? 'color-fff'
                              : item.concluded == 0
                              ? 'color-000'
                              : 'color-fff'
                          }`
                        "
                        >{{ `Cód. ${itemTema.cod}` }}</small
                      >
                      <div class="col-md-7 pl-0 d-flex c-center">
                        <h3
                          :class="
                            `text-left title-concluido title ${
                              itemTema.concluded > 0 &&
                              itemTema.concluded < itemTema.exercicios.length
                                ? 'color-fff'
                                : itemTema.concluded == 0
                                ? 'color-000'
                                : 'color-fff'
                            }`
                          "
                        >
                          {{ itemTema.name }}
                        </h3>
                      </div>
                      <span
                        :class="
                          `border-radius-3 col-md-5 d-flex align-itemTemas-center justify-content-center px-0 ${
                            itemTema.concluded > 0 &&
                            itemTema.concluded < itemTema.exercicios.length
                              ? 'bg-fff pointer hoverStyleButton'
                              : itemTema.concluded == 0
                              ? 'bg-fff no-pointer'
                              : 'bg-fedd0a no-pointer'
                          }`
                        "
                      >
                        <div class="d-flex j-c-center">
                          <div>
                            <span
                              :class="
                                `${
                                  itemTema.concluded > 0 &&
                                  itemTema.concluded < itemTema.exercicios.length
                                    ? 'color-0e5caf'
                                    : itemTema.concluded == 0
                                    ? 'color-000'
                                    : 'color-000'
                                }`
                              "
                              >{{ `${itemTema.concluded}/${itemTema.count}` }}
                              <span>{{
                                itemTema.concluded > 0 &&
                                itemTema.concluded < itemTema.exercicios.length
                                  ? "Continuar"
                                  : itemTema.concluded == 0
                                  ? "Não iniciado"
                                  : "Completo!"
                              }}</span>
                            </span>
                            <img
                              :src="
                                itemTema.concluded > 0 &&
                                itemTema.concluded < itemTema.exercicios.length
                                  ? iconeContinuar
                                  : itemTema.concluded == 0
                                  ? iconeAConcluir
                                  : iconeCompletou
                              "
                              alt="iconAConcluir.png"
                              class="ml-2"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!--elemento para apresentar status das aulas-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalListaExercicios from "@/components/modais/ModalListaExercicios";
import Loader from "@/components/Loader";
export default {
  data() {
    return {
      iconeContinuar: require("@/assets/aluno/iconContinuarBlue.png"),
      iconeCompletou: require("@/assets/aluno/iconCompletouBlack.png"),
      iconeAConcluir: require("@/assets/aluno/iconAConcluir.png"),
      exercicios: [],
      noExercicios: false,
      aulas: [],
      loader: true,
      labelExercicios: null,
    };
  },
  computed: {
    ...mapGetters(["getUserDatas"]),
  },
  components: {
    ModalListaExercicios,
    Loader,
  },
  mounted() {
    this.listaDados();
    localStorage.removeItem("pageExercicios")
  },
  methods: {
    listaDados() {
      let idAluno =
        this.getUserDatas.profile_id == 5 ? "" : this.$route.params.idAluno;
      $.ajax({
        type: "GET",
        url: `${this.VUE_APP_CMS}api/temaGrupo`,
        dataType: "json",
        data: idAluno ? { userId: `${idAluno}` } : "",
        headers: {
          Authorization: this.$store.getters.getPerfilToken,
        },
        success: (response) => {
          this.loader = false;
          this.aulas = response.map((aula) => {
            aula.tema = aula.tema.sort((a,b) => a.order - b.order);
            return aula;
          });
        },
        error: (response) => {
        },
      });
    },

    getTemasExercicios() {
      this.$store
        .dispatch("getTemas", {
          token: this.$store.getters.getPerfilToken,
          page: 1,
        })
        .then((resolve) => {
          console.log('resolve de get tmas aluno meus temas components', resolve)
          if (resolve.valid) {
            this.showLoader = false;
            this.toastGlobal(
              "success",
              "Lista de exercícios atualizada com sucesso"
            );

            this.exercicios = resolve.msg.data;
            if (this.exercicios.length <= 0) this.noExercicios = true;
          } else {
            this.showLoader = false;
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu, ${resolve.msg.responseJSON.msg}`
            );
          }
        });
    },
    showModalExercicios(label, val, index) {
      this.labelExercicios = label;
      let exerciciosIn = [];
      let exerciciosOut = [];

      val.exercicios.forEach((element) => {
        exerciciosOut.push(element);
      });

      this.exercicios = exerciciosOut;
      this.$store.commit('SET_MODAL_LISTA_EXERCICIOS', this.exercicios)
      this.showModal("modalNameModalListaExercicios");
    },

    atualizaDados(val) {
      this.listaDados();
      this.hideModal("modalNameModalListaExercicios");
    },
  },
};
</script>

<style scoped>
.no-pointer:hover {
  cursor: unset;
}
.pointer:hover {
  cursor: pointer;
}
</style>
