<template>
  <div id="Aluno_Meus_Talentos" class="pl-1" v-if="validador($route.meta.typePerfilVerificaRouta, getUserDatas.profile_id)">
    <alunoMeusTemasComponent key="alunoMeusTemasComponent" />
    <!--
    <alunoMeusTemasComponent
      v-if="$route.params.idWhatSee == 1 && show"
      key="alunoMeusTemasComponent"
    />
    <alunoMeusTemasExercicioComponent v-else /> 
    -->
  </div>
</template>

<script>
import alunoMeusTemasComponent from "../components/aluno/AlunoMeusTemasComponent";
import {mapGetters} from 'vuex'
// import alunoMeusTemasExercicioComponent from "../components/aluno/alunoMeusTemasExercicioComponent";
export default {
  data() {
    return {
      show: false,
    };
  },
  components: {
    alunoMeusTemasComponent,
    // alunoMeusTemasExercicioComponent,
  },
  mounted() {
    this.show = true;
  },
  computed:{
    ...mapGetters(['getUserDatas'])
  },
};
</script>

<style scoped></style>
